<template>
  <main>
    <!-- {{ systemGASettingsList }}
    {{ systemFPSettingsList }} -->
    <Slider
      :allow-touch-move="false"
      :slides-per-view="1"
      class="mb-5"
    >
      <template #default>
        <SwiperSlide>
          <img
            class="d-none d-md-block w-100 h-100"
            :class="[$style[`object-fit-cover`], $style[`slider-height`]]"
            src="@/assets/img/index/main_banner.webp"
          >
          <img
            class="d-block d-md-none w-100 h-100"
            :class="[$style[`object-fit-cover`], $style[`slider-height`]]"
            src="@/assets/img/index/main_banner_mobile.webp"
          >
        </SwiperSlide>
      </template>
    </Slider>

    <!-- category -->
    <div class="container-lg mb-5 py-3">
      <div class="row">
        <!-- 必敗專區 -->
        <div class="col-12 col-md-4 mb-5">
          <div
            class="d-none d-md-block"
            :class="$style[`category`]"
          >
            <div class="border border-primary p-2 mb-3">
              <div
                :class="$style[`category-img`]"
                class="w-100 h-0 position-relative overflow-hidden"
                :style="{
                  backgroundImage: `url(${require('@/assets/img/index/category_1.webp')})`
                }"
              >
                <router-link
                  :class="$style[`category-btn`]"
                  to="/product/list?catMainID=a49ea2a3fed94edca30a9c0971e86e41"
                  class="btn w-100 py-2"
                >
                  了解更多
                </router-link>
              </div>
            </div>
            <div class="text-center">
              必敗專區
            </div>
          </div>
          <div
            class="d-block d-md-none"
            :class="$style[`category`]"
          >
            <div class="border border-primary p-2 mb-3">
              <div class="w-100 h-0 position-relative overflow-hidden">
                <img
                  :class="[$style[`category-img`], $style[`object-fit-cover`]]"
                  src="@/assets/img/index/category_1_mobile.webp"
                  alt=""
                  class="w-100 h-100"
                >
              </div>
            </div>
            <div class="text-center mb-4">
              必敗專區
            </div>
            <router-link
              :class="$style[`category-btn`]"
              to="/product/list?catMainID=a49ea2a3fed94edca30a9c0971e86e41"
              class="btn w-100 bg-primary text-white px-4 py-3"
            >
              了解更多
            </router-link>
          </div>
        </div>
        <!-- 嚴選品質 -->
        <div class="col-12 col-md-4 mb-5">
          <div
            class="d-none d-md-block"
            :class="$style[`category`]"
          >
            <div class="border border-primary p-2 mb-3">
              <div
                :class="$style[`category-img`]"
                class="w-100 h-0 position-relative overflow-hidden"
                :style="{
                  backgroundImage: `url(${require('@/assets/img/index/category_2.webp')})`
                }"
              >
                <router-link
                  to="/product/list?catMainID=a49ea2a3fed94edca30a9c0971e86e41"
                  class="btn w-100 py-2"
                  :class="$style[`category-btn`]"
                >
                  了解更多
                </router-link>
              </div>
            </div>
            <div class="text-center">
              嚴選品質
            </div>
          </div>
          <div class="d-block d-md-none category">
            <div class="border border-primary p-2 mb-3">
              <div class="w-100 h-0 position-relative overflow-hidden">
                <img
                  src="@/assets/img/index/category_2_mobile.webp"
                  alt=""
                  :class="[$style[`category-img`], $style[`object-fit-cover`]]"
                  class="w-100 h-100"
                >
              </div>
            </div>
            <div class="text-center mb-4">
              嚴選品質
            </div>
            <router-link
              to="/product/list?catMainID=a49ea2a3fed94edca30a9c0971e86e41"
              class="btn w-100 bg-primary text-white px-4 py-3"
              :class="$style[`category-btn`]"
            >
              了解更多
            </router-link>
          </div>
        </div>
        <!-- 探索香氛 -->
        <div class="col-12 col-md-4 mb-5">
          <div
            class="d-none d-md-block"
            :class="$style[`category`]"
          >
            <div class="border border-primary p-2 mb-3">
              <div
                :class="$style[`category-img`]"
                class="w-100 h-0 position-relative overflow-hidden"
                :style="{
                  backgroundImage: `url(${require('@/assets/img/index/category_3.webp')})`
                }"
              >
                <router-link
                  to="/product/list?catMainID=a49ea2a3fed94edca30a9c0971e86e41"
                  class="btn w-100 py-2"
                  :class="$style[`category-btn`]"
                >
                  了解更多
                </router-link>
              </div>
            </div>
            <div class="text-center">
              探索香氛
            </div>
          </div>
          <div class="d-block d-md-none category">
            <div class="border border-primary p-2 mb-3">
              <div class="w-100 h-0 position-relative overflow-hidden">
                <img
                  src="@/assets/img/index/category_3_mobile.png"
                  alt=""
                  :class="[$style[`category-img`], $style[`object-fit-cover`]]"
                  class="w-100 h-100"
                >
              </div>
            </div>
            <div class="text-center mb-4">
              探索香氛
            </div>
            <router-link
              to="/product/list?catMainID=a49ea2a3fed94edca30a9c0971e86e41"
              class="btn w-100 bg-primary text-white px-4 py-3"
              :class="$style[`category-btn`]"
            >
              了解更多
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- 熱賣商品 -->
    <div class="w-100 bg-white mb-5 py-3">
      <div class="py-0">
        <h4 class="container-lg title-border-bottom mb-3 px-3 pt-5 pt-lg-0">
          人氣熱銷
        </h4>
        <div class="container-lg mb-3">
          <Slider
            :allow-touch-move="true"
            class="mb-5"
            :slides-per-view="2"
            :space-between="40"
            :breakpoints="{
              0: {
                slidesPerView: 2
              },
              768: {
                slidesPerView: 3
              },
              1024: {
                slidesPerView: 4
              }
            }"
          >
            <template #default>
              <SwiperSlide
                v-for="(product, index) in hotProductList"
                :key="index"
                class="mb-3"
              >
                <router-link
                  class="overflow-hidden position-relative d-block text-decoration-none"
                  :to="`/product/detail/${product.productID}`"
                >
                  <!-- <div class="product-tag">HOT</div>
 <div class="product-tag">SALE</div> -->
                  <div
                    class="mb-2"
                    :class="$style[`product-img`]"
                    :style="[
                      product.mainImg
                        ? { backgroundImage: `url(${product.mainImg})` }
                        : {
                          backgroundImage: `url(${require('@/assets/img/nopic.gif')}`
                        }
                    ]"
                  />
                  <div
                    v-if="product.hasStorage"
                    class="position-absolute start-0 top-0 bg-primary text-white px-3 py-1"
                  >
                    現貨
                  </div>
                  <!-- <div v-if="product.hasCw" class="position-absolute start-0 top-0 bg-primary text-white">現貨</div> -->
                  <div class="text-start text-dark">
                    <div class="mb-1">
                      {{ product.name }}
                    </div>
                    <div
                      v-if="product.minPrice == product.maxPrice"
                      class="text-secondary"
                    >
                      NTD ${{ product.minPrice }}
                    </div>
                    <div
                      v-else
                      class="text-secondary"
                    >
                      NTD ${{ product.minPrice }}-{{ product.maxPrice }}
                    </div>
                  </div>
                </router-link>
              </SwiperSlide>
            </template>
          </Slider>

          <!-- <button
          v-if="offset+pageSize<=totalElement && offset+pageSize <16"
          type="button"
          :class="$style[`hover-text-white`]"
          class="btn btn-outline-primary border-2 rounded-0 px-4 py-1"
        >
          更多商品
        </button> -->
        </div>
        <div
          v-if="hotProductList && hotProductList.length === 0"
          class="mb-3 text-center"
        >
          目前尚無人氣熱銷商品<br>
          <router-link
            to="/product/list"
            class="btn btn-primary text-white d-inline-block mt-2 rounded-0"
          >
            去看看其他商品
          </router-link>
        </div>
      </div>
    </div>

    <!-- 關於我們 -->
    <div class="container-fluid pb-5 mb-5">
      <div class="row position-relative">
        <div
          class="col-12 p-0 d-none d-md-flex justify-content-center align-items-center"
        >
          <img
            :class="$style[`object-fit-cover`]"
            src="@/assets/img/index/banner_1.webp"
            alt=""
            class="w-100 h-100"
          >
          <div
            class="col-12 px-0 d-flex flex-column align-items-end container-lg position-absolute position-height-middle"
          >
            <div class="text-primary">
              <div class="bg-primary d-inline-block px-4 py-1 mb-2" />
              <h4 class="font-weight-bold">
                一吃就讓人掉魂的牛奶糖
              </h4>
              <p class="mb-3">
                簡單又奢華的享受<br>
                一入口就能品嘗到夢幻雲朵般的滋味
              </p>
              <button
                :class="$style[`hover-text-white`]"
                class="btn btn-outline-primary border-2 rounded-0 px-4 py-1"
                type="button"
              >
                了解更多
              </button>
            </div>
          </div>
        </div>
        <div
          class="d-flex d-md-none justify-content-center align-items-center"
          :class="$style[`about-us-mobile`]"
        >
          <img
            src="@/assets/img/index/banner_1_mobile.webp"
            alt=""
            class="w-100"
          >
          <div
            class="d-flex flex-column align-items-end container-lg position-absolute bottom-0"
          >
            <!-- <div class="d-block d-md-none container-lg position-absolute r-0 text-primary text-end position-height-middle"> -->
            <div class="text-primary text-end pb-3">
              <div class="bg-primary d-inline-block px-4 py-1 mb-2" />
              <h4 class="font-weight-bold">
                一吃就讓人掉魂的牛奶糖
              </h4>
              <p class="mb-3">
                簡單又奢華的享受<br>
                一入口就能品嘗到夢幻雲朵般的滋味
              </p>
              <button
                :class="$style[`hover-text-white`]"
                class="btn btn-outline-primary border-2 rounded-0 px-4 py-1"
                type="button"
              >
                了解更多
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 堅持 -->
    <div class="container-md mb-5 col-lg-6">
      <img
        :class="$style[`index-banner`]"
        class="img-fluid mb-3 w-100"
        src="@/assets/img/index/banner_2.webp"
      >
      <p class="text-center line-height-lg">
        日本及韓國美妝/日用品/服飾 代購<br>
        代購商品屬於販售代購服務，不提供退換及保固<br>
        代購是直接請當地朋友於正式商店購買，商品保證正品<br>
        商品大部分皆採預購方式，下單後約2~3周抵台，<br>
        若不耐久等請勿下單
      </p>
    </div>
  </main>
</template>
<script>
import { ref, computed, onMounted, createApp } from 'vue'
import { useStore } from 'vuex'
import Slider from '@/components/Slider.vue'
import { SwiperSlide } from 'swiper/vue'
import VueGtag from 'vue-gtag-next'

export default {
  name: 'Home',
  components: {
    Slider,
    SwiperSlide
  },
  async setup () {
    const { getters, dispatch } = useStore()

    // 熱賣商品清單
    const clientID = computed(() => getters['system/getClientID'])
    const systemGASettings = computed(
      () => getters['system/getSystemGASettings']
    )
    const systemFPSettings = computed(
      () => getters['system/getSystemFPSettings']
    )
    const systemGASettingsList = computed(() => {
      return systemGASettings.value.gaUserID
    })
    const systemFPSettingsList = computed(() => {
      return systemFPSettings.value.fbpixelUserID
    })
    const hotProductList = computed(() => getters['product/getHotProductList'])
    // const orderID = computed(() => getters['order/getOrderID'])

    // GA分析
    const gaCode = createApp()
    gaCode.use(VueGtag, {
      property: {
        id: systemGASettingsList
      }
    })

    // 結帳完回網頁，需導至結果頁
    // console.log(orderID.value)
    // if (orderID.value) {
    //   push('/order-confirm')
    // }

    const hotProductNextPage = ref(1)
    const offset = ref(0)
    const pageSize = ref(8)
    const totalElement = ref(0)
    const totalPage = ref(0)
    const getDataParm = ref({
      offset: '',
      pageSize: ''
    })

    // 取得熱賣 / 熱銷商品
    const readHotProductList = async pageNumber => {
      getDataParm.value = {
        offset: pageNumber * pageSize.value,
        pageSize: pageSize.value
      }
      try {
        const response = await dispatch('product/readHotProductList', {
          payload: getDataParm.value
        })
        console.log(response)
        hotProductNextPage.value = pageNumber
        offset.value = response.data.data.page.offset
        pageSize.value = response.data.data.page.pageSize
        totalElement.value = response.data.data.page.totalElement
        totalPage.value = response.data.data.page.totalPage
      } catch (error) {
        console.log(error)
      }
    }

    const readGASystemSettings = async () => {
      const payload = {
        clientID: clientID.value
      }

      try {
        const response = await dispatch('system/readGASystemSettings', {
          payload
        })
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
    const readFPSystemSettings = async () => {
      const payload = {
        clientID: clientID.value
      }

      try {
        const response = await dispatch('system/readFPSystemSettings', {
          payload
        })
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
    onMounted(() => {
      readGASystemSettings()
      readFPSystemSettings()
    })
    await readHotProductList(0)

    return {
      hotProductList,
      totalElement,
      offset,
      pageSize,
      systemGASettings,
      systemGASettingsList,
      systemFPSettingsList,
      readGASystemSettings,
      readFPSystemSettings
    }
  }
}
</script>
<style lang="scss" module>
.hover-text-white:hover {
  color: $white;
}

.slider-height {
  height: 400px;
}

.object-fit-cover {
  object-fit: cover;
}

.about-us-mobile {
  background-color: rgb(244, 240, 241);
  padding-bottom: 5rem;
}

.index-banner {
  object-position: 50% 50%;
}

/* product-list-wrap */
.hot-product-list-wrap {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.3s;
}

.hot-product-list-wrap.rendered {
  max-height: 200vh;
  opacity: 1;
}

.product-tag {
  background-color: rgb(var(--pantone-color));
  color: $white;
  font-weight: bold;
  left: 0.5rem;
  padding: 0.75rem 1.25rem;
  position: absolute;
  top: 0;
}

.product-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 100%;
}

/* category */
.category {
  font-size: 1.2rem;
}

.category .category-btn {
  background-color: $tertiary;
  border-radius: 0;
  color: $white;
}

@media screen and (min-width: 768px) {
  .category-img {
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100%;
  }

  .category .category-btn {
    background-color: $tertiary;
    border-radius: 0;
    bottom: 0;
    color: $white;
    left: 0;
    position: absolute;
    transform: translateY(100%);
    transition: background-color 0.3s, transform 0.3s;
  }

  .category:hover .category-btn {
    transform: translateY(0%);
  }

  .category:hover .category-btn:hover {
    background-color: $primary;
  }
}
</style>
